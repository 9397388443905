<template>
  <div>
    <section
      class="g-bg-pos-center g-bg-size-cover"
      style="background-image: url(assets/img/fondo-ingreso-wide.jpg);height:300px;"
    >
      <div class="container g-color-white text-center g-py-210"></div>
    </section>

    <section class="container-fluid g-px-100--lg g-px-50--sm g-py-80">
      <h1 class="g-font-weight-500 g-mb-15 font-color-title">
        Ingresar una consulta
      </h1>
      <hr class="g-my-40 " style="border: 1px solid;color: #555; !important" />
      <div class="row no-gutters g-mx-minus-10">
        <div class="col-sm-6 col-lg-6 g-px-10 text-center">
          <router-link
            to="#"
            v-on:click.native="enter(false)"
            class="btn btn-xl u-btn-green u-btn-content g-mr-2 g-mb-15"
            ><img src="assets/img/new-user.svg" />Usuario Nuevo</router-link
          >
        </div>
        <div class="col-sm-6 col-lg-6 g-px-10 text-center">
          <router-link
            to="#"
            v-on:click.native="showDialogLogin = true"
            class="btn btn-xl u-btn-orange u-btn-content g-mr-2 g-mb-15"
            ><img src="assets/img/register-user.svg" />Usuario
            Registrado</router-link
          >
        </div>
      </div>
    </section>

    <Login
      :showDialogLogin="showDialogLogin"
      @abort="showDialogLogin = false"
    />
  </div>
</template>

<script>
import Login from "@/components/PublicSite/Login";

import baseViewMixin from "@/mixins/baseView";
import currentQueryInCreationMixin from "@/mixins/currentQueryInCreation";

export default {
  mixins: [baseViewMixin, currentQueryInCreationMixin],
  components: { Login },
  data() {
    return {
      showDialogLogin: false,
    };
  },
  methods: {
    enter(registeredUser) {
      const fromPublicSite = true;

      this.startCurrentQueryInCreation(registeredUser, fromPublicSite).then(
        () => {
          this.gotoView("selectionUserTypeQuery");
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
a.btn {
  color: white !important;
}
.font-color-title {
  color: #555;
  font-size: 2.5rem;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}
</style>
